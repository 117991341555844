.movable-block-container, .movable-block-container:hover {
    position: absolute !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 1;
}

.movable-block-container.bg-warning-transparent {
    background-color: rgba(255, 240, 200, 0.4) !important;
}
