@import '../variables';

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus {
    color: $base-active-color !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: none !important;
    outline: none !important;
}

.navbar-light .navbar-nav .nav-light:focus a {
    color: $base-focus-color !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: $base-hover-color !important;
}
