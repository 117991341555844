.article-image-container {
    display: inline-block;
    padding: 8px 8px 0px 8px;
}

.article-image-holder {
    display: inline-block;
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    width: 240px;
    height: 240px;
    overflow: hidden;
}

.article-image-container-wide {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .article-image-container-wide {
        width: 50%;
    }
}

.article-image-container-wide .article-image-holder {
    min-width: 240px;
    width: 100%;
    height: auto;
}

.article-image-holder img {
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;
}

.article-image-holder img:hover {
    opacity: 0.8;
    cursor: pointer;
}
