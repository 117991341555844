.article-block {
    min-height: 50px;
    padding: 0.5rem 0;
}

.social-media-icon-logo {
    width: 24px;
}

.social-media-icon-logo-big {
    width: 50px;
}

.link-text-left {
    text-align: left !important;
}
