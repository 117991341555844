.images-list-container {
    height: 60vh;
    overflow-y: scroll;
}

.image-radio-container {
    position: relative;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    width: 200px !important;
    height: 200px !important;
    border-radius: 5px;
    overflow: hidden;
}

.image-radio-container img {
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;
    border-radius: 5px;
}

.image-radio-container .form-check input[type="radio"] {
    position: absolute;
    top: 5px;
    left: 35px;
}

.image-radio-container .form-check-label {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
}
