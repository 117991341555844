@import '../variables';

.accordion-button, .accordion-button:focus, .accordion-button:not(.collapsed) {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: none !important;
    outline: none !important;
    color: rgba(0, 0, 0, 0.55) !important;
    margin: 0 auto !important;
    padding: 0.5rem 0px !important;
    width: fit-content !important;
}

.accordion-button:focus {
    color: $base-focus-color !important;
}

.accordion-button:hover {
    color: $base-hover-color !important;
}

.accordion-button:after {
    margin-left: 10px !important;
    width: 10px !important;
    height: 10px !important;
    background-size: 10px !important;
}

.dropdown-smaller-text .menu-text-fancy {
    font-size: 1.6rem !important;
}

.dropdown-smaller-text .dropdown-smaller-text .menu-text-fancy {
    font-size: 1.2rem !important;
}

.dropdown-smaller-text .dropdown-smaller-text .dropdown-smaller-text .menu-text-fancy {
    font-size: 1rem !important;
}
