@media only screen and (min-width: 768px) {
    .left-sidebar-block {
        position: fixed !important;
        top: 0px;
        bottom: 0px;
        left: 15px;
        padding-right: 15px !important;
        overflow-y: scroll;
    }

    .right-content-block {
        position: fixed !important;
        top: 0px;
        bottom: 0px;
        right: 0px;
        overflow-y: scroll;
        background-color: white;
    }
}

@media only screen and (min-width: 1400px) {
    .right-content-block {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }
}

.menu-text-fancy, .menu-text-fancy * {
    font-family: 'Amatic SC', cursive !important;
    font-size: 2rem !important;
}

@import '~bootstrap/scss/bootstrap';
