.content-editable-block-container {
    height: 60vh;
    overflow-y: scroll;
}

.content-editable-block {
    min-height: 50vh;
    border: 1px solid lightgrey !important;
    outline: none !important;
}

.content-editable-block * {
    text-align: justify;
}

.content-editable-block p, .content-editable-block h1 {
    text-align: center;
}

.text-editor-icon {
    height: 35px;
    width: 30px;
}
