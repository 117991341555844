.article-block {
    min-height: 50px;
    padding: 0.5rem 0;
}

.article-text-div {
    text-align: justify !important;
}

.article-text-div p, .article-text-div h1 {
    text-align: center;
}
